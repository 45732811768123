import config from '../components/config';

export const getUserRole = async (email) => {
  try {
    const response  = await fetch(`${config.API_URL}/get_user_role.php`, {method: 'POST',headers: {  'Content-Type': 'application/json',},body: JSON.stringify({ email }),});
    const data      = await response.json();
    return data;
  } catch (error) {
    console.error('Error validating user:', error);
    return null;
  }
};
