export const products = {
  name: "Products",
  slug: "products",
  fields: [
    {
      key: "ID",
      type: "string",
      label: "ID",
      constraints: [
        {
          "type": "unique"
        }, 
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Name",
      type: "string",
      label: "Name",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Description",
      type: "string",
      label: "Description"
    },
    {
      key: "GroupID",
      type: "string",
      label: "Product Group ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Type",
      type: "string",
      label: "Product Type",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit this data to a webhook.',
      primary: true,
      constraints: [{ type: 'hasAllValid' }]
    }
  ]
};
