export const purchase_order_recieved = {
  name: "Purchase Order Recieved",
  slug: "purchase_order_recieved",
  fields: [
    {
      key: "PORNumber",
      type: "string",
      label: "POR Number",
      constraints: [
        {
          "type": "unique"
        },
        {
          "type": "required"
        }
      ],
    },
    {
      key: "RequestDate",
      type: "string",
      label: "Request Date",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "ClientBranchId",
      type: "string",
      label: "Client Branch ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    }, 
    {
      key: "AgreedDeliveryDate",
      type: "string",
      label: "Agreed Delivery Date",
    },
    {
      key: "InvoiceNumber",
      type: "string",
      label: "Invoice Number"
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit this data to a webhook.',
      primary: true,
      constraints: [{ type: 'hasAllValid' }]
    }
  ]
};
