import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Box from './Box';
import './DashboardPage.css';
import config from './config';

const DashboardPage = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth0();
    const [authLoading, setAuthLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);

    useEffect(() => {
        if (isAuthenticated && user?.email) {
            axios
                .get(`${config.API_URL}/admin_rights.php`, { params: { email: user.email } })
                .then((response) => {
                    if (response.data.success) {
                        setAccessDenied(false);
                    } else {
                        setAccessDenied(true);
                    }
                })
                .catch((err) => {
                    console.error("Error checking admin rights:", err);
                    setAccessDenied(true);
                })
                .finally(() => {
                    setAuthLoading(false);
                });
        } else {
            setAuthLoading(false);
            setAccessDenied(true);
        }
    }, [isAuthenticated, , user]);

    const handleMonitorKPIsClick = () => {
        navigate('/reports');
    };

    const handlePerformanceAnalysisClick = () => {
        navigate('/workbooks_ref');
    };

    const handleDBManagement = () => {
        navigate('/db-management');
    };
 
    if (authLoading) {
        // Show loader while checking authorization
        return (
            <Container className="text-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p>Checking authorization, please wait...</p>
            </Container>
        );
    }

    return (
        <div className="dashboard-container container-fluid"> {/* Bootstrap container for responsiveness */}
            <div className="row box-container"> {/* Bootstrap row */}
                <div className="col-md-6 col-lg-6 mb-4"> {/* Bootstrap grid column with margins */}
                    <Box 
                        title="Monitor KPIs"
                        content="Make better informed decisions"
                        color="blue"
                        svg="/monitor-kpis.svg"
                        onClick={handleMonitorKPIsClick}
                    />
                </div>
                <div className="col-md-6 col-lg-6 mb-4">
                    <Box 
                        title="Performance Analysis"
                        content="Track what you want to improve"
                        color="grey"
                        svg="/performance-analysis.svg"
                        onClick={handlePerformanceAnalysisClick}
                    />
                </div>
                <div className="col-md-6 col-lg-6 mb-4">
                    <Box 
                        title="Sales Planning"
                        content="Anticipate what your customers want"
                        color="grey"
                        svg="/sales-plannign.svg"
                    />
                </div>
                <div className="col-md-6 col-lg-6 mb-4">
                    <Box 
                        title="Operations Planning"
                        content="Get the right goods to the right place, at the right time"
                        color="blue"
                        svg="/operations-planning.svg"
                    />
                </div>
            </div>

            {!accessDenied && (
                <>
                    <p onClick={handleDBManagement}>Temporary `db-management` page link</p>
                </>
            )}
        </div>
    );
};

export default DashboardPage;