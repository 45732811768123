export const client_branches = {
  name: "Client Branches",
  slug: "client_branches",
  fields: [
    {
      key: "ID",
      type: "string",
      label: "ID",
      constraints: [
        {
          "type": "unique"
        },
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Name",
      type: "string",
      label: "Name",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Description",
      type: "string",
      label: "Description",
    },
    {
      key: "ClientID",
      type: "string",
      label: "Client ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "ChannelID",
      type: "string",
      label: "Channel ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Location",
      type: "string",
      label: "Location",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit this data to a webhook.',
      primary: true,
      constraints: [{ type: 'hasAllValid' }]
    }
  ]
};
