// Import individual sheets
import { products } from "./products";
import { channels } from "./channels";
import { clients } from "./clients";
import { client_branches } from "./client_branches";
import { exchange_rates } from "./exchange_rates";
import { invoice_lines } from "./invoice_lines";
import { purchase_order_recieved } from "./purchase_order_recieved";
import { purchase_order_recieved_lines } from "./purchase_order_recieved_lines";

// Define multiple workbooks
export const referenceDataWorkbook = {
  name: "Reference data",
  labels: ["reference"],
  namespace: "portal_ref_data",
  sheets: [
    products,
    channels,
    exchange_rates,
    clients,
    client_branches,
  ]
};

export const salesDataWorkbook = {
  name: "Sales data",
  labels: ["sales"],
  namespace: "portal_sales_data",
  sheets: [
    invoice_lines
  ]
};

export const serviceDataWorkbook = {
  name: "Service data",
  labels: ["service"],
  namespace: "portal_service_data",
  sheets: [
    purchase_order_recieved,
    purchase_order_recieved_lines
  ]
};

// Export all workbooks in an array
export const workbook = [
  referenceDataWorkbook,
  salesDataWorkbook,
  serviceDataWorkbook
];
