export const exchange_rates = {
  name: "Exchange Rates",
  slug: "exchange_rates",
  fields: [
    {
      key: "otherCurrency",
      type: "string",
      label: "Other Currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "RateToBaseCurrency",
      type: "number",
      label: "Rate To Base Currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "EffectiveFrom",
      type: "string",
      label: "Effective From",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit this data to a webhook.',
      primary: true,
      constraints: [{ type: 'hasAllValid' }]
    }
  ]
};
