import React from "react";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import "./LoginPage.css";
import logo from "../images/logo-small.svg";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0(); // Auth0 login method

  return (
    <div id="login-page">
      <div class="with-sidebar">
        <div class="blue-background">
          <div class="logo-content">
            <img src={logo} alt="Pivotal logo"></img>
            <h1 class="main-heading">Optimising Supply Chains</h1>
          </div>

          <div className="circles">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1489"
              height="1488"
              fill="none"
              viewBox="0 0 1489 1488"
            >
              <g clip-path="url(#a)">
                <path
                  stroke="#6FAFE6"
                  stroke-width="1.333"
                  d="M934.374 553.212c52.699 52.47 79.106 116.347 79.106 191.805 0 74.122-26.404 137.335-79.105 189.673l-.001.002c-52.434 52.336-116.267 78.628-191.674 78.628-73.671 0-136.841-26.288-189.544-78.628-52.434-52.338-78.578-115.551-78.578-189.675 0-75.459 26.146-139.336 78.578-191.805 52.703-52.341 115.873-78.634 189.544-78.634 75.407 0 139.24 26.297 191.674 78.634Z"
                  opacity=".167"
                />
                <path
                  stroke="#6FAFE6"
                  stroke-width="1.333"
                  d="M1001.33 486.098c71.24 70.931 106.94 157.289 106.94 259.293 0 100.202-35.7 185.661-106.94 256.409v.01c-70.875 70.75-157.167 106.3-259.093 106.3-99.586 0-184.981-35.55-256.22-106.31-70.877-70.747-106.218-156.206-106.218-256.409 0-102.005 35.344-188.363 106.218-259.292 71.239-70.756 156.634-106.303 256.22-106.303 101.926 0 188.218 35.55 259.093 106.302Z"
                  opacity=".333"
                />
                <path
                  stroke="#6FAFE6"
                  stroke-width="1.333"
                  d="m1068.28 418.983.01.001c89.77 89.392 134.76 198.23 134.76 326.781 0 126.281-44.99 233.986-134.76 323.155h-.01c-89.315 89.17-198.065 133.97-326.512 133.97-125.498 0-233.118-44.8-322.894-133.97-89.319-89.168-133.859-196.873-133.859-323.155 0-128.552 44.542-237.39 133.859-326.781 89.776-89.169 197.396-133.97 322.894-133.97 128.447 0 237.197 44.804 326.512 133.969Z"
                  opacity=".5"
                />
                <path
                  stroke="#6FAFE6"
                  stroke-width="1.333"
                  d="M1135.24 351.869v.001c108.31 107.853 162.59 239.172 162.59 394.269 0 152.36-54.28 282.311-162.59 389.891-107.76 107.58-238.973 161.64-393.941 161.64-151.412 0-281.256-54.05-389.568-161.64-107.762-107.58-161.5-237.53-161.5-389.891 0-155.099 53.74-286.417 161.5-394.269 108.312-107.583 238.157-161.639 389.568-161.639 154.968 0 286.181 54.059 393.941 161.638Z"
                  opacity=".667"
                />
                <path
                  stroke="#6FAFE6"
                  stroke-width="1.333"
                  d="M1202.19 1203.15c-126.2 125.99-279.87 189.3-461.357 189.3-177.326 0-329.395-63.3-456.243-189.3-126.206-126-189.141-278.197-189.141-456.637 0-181.646 62.938-335.445 189.142-461.758C411.439 158.758 563.508 95.449 740.833 95.449c181.487 0 335.157 63.312 461.357 189.306l.47-.468-.47.469c126.85 126.314 190.42 280.113 190.42 461.757 0 178.439-63.57 330.637-190.42 456.637Z"
                  opacity=".833"
                />
                <path
                  stroke="#6FAFE6"
                  stroke-width="1.333"
                  d="m1269.15 217.641.45-.452-.45.453c145.38 144.775 218.25 321.054 218.25 529.244 0 204.519-72.87 378.964-218.25 523.374-144.65 144.41-320.776 216.98-528.784 216.98-203.239 0-377.533-72.57-522.918-216.98C72.8 1125.85.667 951.406.667 746.886c0-208.191 72.135-384.47 216.782-529.244C362.833 73.23 537.127.667 740.366.667c208.008 0 384.134 72.566 528.784 216.974Z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h1488.06v1487.9H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="login-wrapper">
          <div class="card text-center rounded">
            <div class="card-body">
              <div class="d-flex flex-column justify-content-center align-items-center my-4">
                <h2 class="h3">Log in to your account</h2>
                <p class="mt-1">
                  Use the link below to log in to your Pivotal account.
                </p>
                <button
                  id="login-button"
                  class="btn btn-primary mt-3"
                  onClick={() => loginWithRedirect()}
                >
                  Login with Auth0
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
