export const purchase_order_recieved_lines = {
  name: "Purchase Order Recieved Lines",
  slug: "purchase_order_recieved_lines",
  fields: [
    {
      key: "PORNumber",
      type: "string",
      label: "POR Number",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "ProductID",
      type: "string",
      label: "Product ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Units",
      type: "string",
      label: "Quantity of Units Requested",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "SellingPriceInClientCurrency",
      type: "string",
      label: "Selling Price In Client Currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit this data to a webhook.',
      primary: true,
      constraints: [{ type: 'hasAllValid' }]
    }
  ]
};
