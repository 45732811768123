import config from '../components/config';

export const validateUser = async (email, logout) => {
  try {
    const response = await fetch(`${config.API_URL}/validate_user.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (!data.success) {
      if (data.company && data.company.CAH) { 
        alert("Access denied. Please contact " + data.company.CAH + " to check your company’s subscription status");
      } else {
        alert(data.message || "An error occurred. Please try again.");
      }
      logout();
      return null;
    }  

    try { // GETTING LOGO IMAGE FOR COMPANY
        const response_img = await fetch(`${config.API_URL}/get_logo.php?id=${data.company.id}`, { method: 'GET', });
        
        if (!response_img.ok) {
            throw new Error(`Failed to fetch logo: ${response_img.statusText}`);
        }
        const blob = await response_img.blob();
        const imageUrl = URL.createObjectURL(blob);
        data.company.company_logo = imageUrl;
    
    } catch (error) {
        console.error('Error fetching logo:', error);
    }

    // Store the data in localStorage for persistence
    localStorage.setItem('companyData', JSON.stringify(data.company));
    localStorage.setItem('userData', JSON.stringify(data.users));

    return data;
  } catch (error) {
    console.error('Error validating user:', error);
    return null;
  }
};
