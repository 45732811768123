import React, { useState, useEffect } from 'react';
import {
    useFlatfile,
    usePlugin,
    useListener,
    useEvent,
    Workbook,
    Space,
    FlatfileProvider,
} from "@flatfile/react";
import { recordHook } from "@flatfile/plugin-record-hook";
import { serviceDataWorkbook } from "../configs/workbook"; 
import './WorkbooksPage.css';
import { useNavigate } from 'react-router-dom';
import config from "./config";
import api  from "@flatfile/api";
import { useAuth0 } from '@auth0/auth0-react'; 
import { getUserRole } from '../utils/getUserRole';


const WorkbooksPage_service = () => {
    const { user } = useAuth0(); 
    const [selectedSidebar, setSelectedSidebar] = useState('Service data'); // default to Reference data
    const PUBLISHABLE_KEY = 'pk_17dd17c7c44a4dbeb844920b883a3c05';
    const navigate = useNavigate();
    const companyData = JSON.parse(localStorage.getItem("companyData"));
    const [workbook_ref, setWorkbookRef]            = useState([]);
    const [workbook_sale, setWorkbookSale]          = useState([]);
    const [workbook_service, setWorkbookService]    = useState([]);

    const fetchFlatfileDataForWorkbook = async (workbook_name) => {
        try {
            const response = await fetch(`${config.API_URL}/fetch-flatfile-data.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ companyData, workbook_name }),
            });
    
            const data = await response.json();
    
            if (data.status === "success" && data.data) {
                const sheets_records = data.data;
    
                const mappings = {
                    reference: ["Products", "Channels", "ExchangeRates", "Clients", "ClientBranches"],
                    sale: ["InvoiceLines"],
                    service: ["PurchaseOrderRecieved", "PurchaseOrderRecievedLines"],
                };
    
                if (!mappings[workbook_name]) {
                    throw new Error(`Invalid workbook name provided: ${workbook_name}`);
                }
    
                const mappedRecords = mappings[workbook_name].reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {});
    
                let count = 0;
                for (const [key, records] of Object.entries(sheets_records)) {
                    if (records && records.length > 0) {
                        const targetKey = mappings[workbook_name][count];
                        if (targetKey) {
                            mappedRecords[targetKey] = records;
                        } else {
                            console.warn(`No target mapping for index ${count} in workbook '${workbook_name}'`);
                        }
                    } else {
                        console.warn(`No records found for key '${key}'`);
                    }
                    count++;
                }
    
                switch (workbook_name) {
                    case "reference":
                        setWorkbookRef(mappedRecords);
                        break;
                    case "sale":
                        setWorkbookSale(mappedRecords);
                        break;
                    case "service":
                        setWorkbookService(mappedRecords);
                        break;
                    default:
                        console.warn(`Unhandled workbook type: ${workbook_name}`);
                }
    
            } else {
                const errorMessage = data.message || "Invalid data structure received";
                console.error("Failed to fetch dynamic columns or records:", errorMessage);
            }
        } catch (error) {
            console.error("Error during workbook or record processing:", error.message || error);
        }
    };

    const handleSidebarClick = (sidebar) => {
        setSelectedSidebar(sidebar);
        // Change workbook based on the selected sidebar
        switch(sidebar) {
            case 'Reference data':
                navigate('/workbooks_ref');
                break;
            case 'Sales data':
                navigate('/workbooks_sale');
                break;
            case 'Service data':
                navigate('/workbooks_service');
                break;
            default:
                navigate('/workbooks_ref');
            }

    };

    const sendDataToApi = async (companyData, refferenceWorkbookId, saleWorkbookId, serviceWorkbookId) => {
        try {
            const response = await fetch(`${config.API_URL}/save-data.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ companyData, refferenceWorkbookId, saleWorkbookId, serviceWorkbookId }),
            });
    
            const responseText = await response.text(); // Get raw response as text
    
            if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);
    
            // Check if the response text is empty
            if (!responseText) {
                throw new Error("The server returned an empty response.");
            }
    
            let result;
            try {
                result = JSON.parse(responseText); // Try parsing the JSON response
            } catch (error) {
                console.error("Failed to parse response as JSON:", error);
                throw new Error("The server returned an invalid JSON response.");
            }
    
            if (result.status != "success") {
                console.error("Error saving data:", result.message);
            }
        } catch (error) {
            console.error("API request failed:", error);
        }
    };

    const SpaceConfig = () => {
        const { openPortal } = useFlatfile();
        const products                      = workbook_ref.Products;
        const clientBranches                = workbook_ref.ClientBranches;
        const invoiceLines                  = workbook_sale.InvoiceLines;
        const [purchaseOrderRecieved, setPurchaseOrderRecieved] = useState(workbook_service.PurchaseOrderRecieved);

        

        // Automatically open the portal when the component mounts
        useEffect(() => {
            openPortal();
        }, [openPortal]);
        
        useEvent("job:ready", { job: "sheet:submitActionFg" }, async (event) => {
            const { jobId, workbookId } = event.context;

            const fetchUserRole = await getUserRole(user.email);
            const userRole = fetchUserRole.role;
            // Check if the user role is either 'admin' or 'collaborator'
            if (userRole != 'admin' && userRole != 'collaborator') {
                console.error("User  does not have permission to submit data.");
                await api.jobs.fail(jobId, {
                    outcome: {
                        message: "You do not have permission to submit data.",
                    },
                });
                return;
            }
            try {
                await api.jobs.ack(jobId, {
                    info: "Submitting the data.",
                    progress: 30,
                });
            
                const refferenceWorkbookId  = null;
                const saleWorkbookId        = null;
                const serviceWorkbookId     = workbookId;

                
                try {
                    await sendDataToApi(companyData, refferenceWorkbookId, saleWorkbookId, serviceWorkbookId);
                } catch (error) {
                    console.error("Error Sending data to API:", error);
                }

                await api.jobs.complete(jobId, {
                    outcome: {
                        message: "The data has been submitted.",
                    },
                });

            } catch (error) {
                console.error("Error:", error);
            
                await api.jobs.fail(jobId, {
                    outcome: {
                        message: "This job encountered an error.",
                    },
                });
            }
        });

        useListener((listener) => {
            listener.on("workbook:created", async (event) => {
                const { workbookId } = event.context;
                const workbook_name     = 'service'; 

                fetchFlatfileDataForWorkbook('reference');
                fetchFlatfileDataForWorkbook('sale');
                fetchFlatfileDataForWorkbook('service');
                
                try {
                    const response = await fetch(`${config.API_URL}/fetch-flatfile-data.php`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ companyData, workbook_name }),
                    });
        
                    const data = await response.json();
        
                    if ( data.status === "success" && data.data) {
                        const sheets_records = data.data;
                        
                        let count = 0; 
                        for (const key in sheets_records) {
                            if (sheets_records.hasOwnProperty(key)) {
                                const records = sheets_records[key];                         
                                if (records && records.length > 0) {
                                
                                    try {
                                        const workbook = await api.workbooks.get(workbookId);
                                    
                                        if (workbook && workbook.data && workbook.data.sheets) {
                                            const sheetId = workbook.data.sheets[count].id; 
                                            
                                            // INSERTING DATA INTO SHEETS 
                                            await api.records.insert(sheetId, records);
                                        } else {
                                            console.warn("No sheets found in the workbook."); 
                                        }
                                    } catch (error) {
                                        console.error("Error during workbook or record insertion:", error);
                                    }
                                } else {
                                    console.error("No records found in the response.");
                                }
                            }
                            count++; 
                        }
                    } else {
                        console.error("Failed to fetch dynamic columns or records:", data.message || "Invalid data structure");
                    }
                    
                } catch (error) {
                    console.error("Error during workbook or record insertion:", error);
                }
            });
        });

        usePlugin(
            recordHook("purchase_order_recieved", (record) => {
        
                // Extract fields using `record.get()`
                const purchaseOrderData = {
                    PORNumber: {value: record.get("PORNumber")},
                    RequestDate: {value: record.get("RequestDate")},
                    ClientBranchId: {value: record.get("ClientBranchId")},
                    AgreedDeliveryDate: {value: record.get("AgreedDeliveryDate")},
                    InvoiceNumber: {value: record.get("InvoiceNumber")},
                };
        
        
        
                // Use `record.rowId` as the unique identifier
                const recordId = record.rowId || record.get("rowId");
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                    return record; // Exit if rowId is missing
                }
        
                // Add the `recordId` to the purchase order data
                purchaseOrderData.rowId = recordId;
        
               

                // Validate the foreign key (clientBranchId) against `clientBranches`
                const clientBranchId = purchaseOrderData.ClientBranchId.value;
                if (!clientBranches.some((clientBranch) => clientBranch.ID.value === clientBranchId)) {
                    const errorMsg = `Client Branch ID ${clientBranchId} does not exist in the Client Branch table.`;
                    
                    record.addError("ClientBranchId", errorMsg);
                }

                // Validate the foreign key (invoiceNumber) against `invoiceLines`
                const invoiceNumber = purchaseOrderData.InvoiceNumber.value;
                if (invoiceNumber !== null && invoiceNumber !== undefined) {
                    if (!invoiceLines.some((invoiceLine) => invoiceLine.InvoiceNumber.value === invoiceNumber)) {
                        const errorMsg = `Invoice Number ${invoiceNumber} does not exist in the Invoice Lines table.`;
                        record.addError("InvoiceNumber", errorMsg);
                    }
                }
                
                const validDateFormat = companyData.date_format === 'mm/dd/yyyy' 
                ? /^\d{1,2}\/\d{1,2}\/\d{4}$/  // Matches mm/dd/yyyy format
                : /^\d{1,2}\/\d{1,2}\/\d{4}$/; // Matches dd/mm/yyyy format (same regex, but validation differs)
            
                
                if (!validDateFormat.test(purchaseOrderData.RequestDate.value)) {
                    record.addError("RequestDate", `Request Date must be a valid date in the format ${companyData.date_format}.`);
                } else {
                    const [part1, part2, year] = purchaseOrderData.RequestDate.value.split('/').map(num => parseInt(num, 10));
                    let month, day;
                
                    // Adjust based on the date format
                    if (companyData.date_format === 'mm/dd/yyyy') {
                        month = part1;
                        day = part2;
                    } else if (companyData.date_format === 'dd/mm/yyyy') {
                        day = part1;
                        month = part2;
                    }
                
                    // Check if the date is valid
                    const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
                    if (date.getMonth() !== month - 1 || date.getDate() !== day || date.getFullYear() !== year) {
                        record.addError("RequestDate", "Request Date is not a valid calendar date.");
                    }
                }   
                
             

                // Validate AgreedDeliveryDate based on company data format
                if (purchaseOrderData.AgreedDeliveryDate.value) {
                    // Check if the date string matches the expected format
                    if (!validDateFormat.test(purchaseOrderData.AgreedDeliveryDate.value)) {
                        record.addError("AgreedDeliveryDate", `Agreed Delivery Date must be a valid date in the format ${companyData.date_format}.`);
                    } else {
                        const [part1, part2, year] = purchaseOrderData.AgreedDeliveryDate.value.split('/').map(num => parseInt(num, 10));
                        let month, day;

                        // Adjust based on the date format
                        if (companyData.date_format === 'mm/dd/yyyy') {
                            month = part1;
                            day = part2;
                        } else if (companyData.date_format === 'dd/mm/yyyy') {
                            day = part1;
                            month = part2;
                        }

                        // Check if the date is valid
                        const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
                        if (date.getMonth() !== month - 1 || date.getDate() !== day || date.getFullYear() !== year) {
                            record.addError("AgreedDeliveryDate", "Agreed Delivery Date is not a valid calendar date.");
                        }
                    }
                }

                // Store the PO data in the `PurchaseOrderRecieved` state
                setPurchaseOrderRecieved((prev = []) => {
        
                    // Update the state by replacing or adding the new record
                    const updatedPurchaseOrderRecieved = [
                        ...prev.filter((po) => po.rowId !== recordId), // Remove the old record if it exists
                        purchaseOrderData, // Add the new or updated record
                    ];
        
                    return updatedPurchaseOrderRecieved;
                });

                return record; // Return the processed record
            })
        );

        usePlugin(
            recordHook("purchase_order_recieved_lines", (record) => {
        
                // Extract fields using `record.get()`
                const purchaseOrderLineData = {
                    PORNumber: record.get("PORNumber"),
                    ProductID: record.get("ProductID"),
                    Units: record.get("Units"),
                    SellingPriceInClientCurrency: record.get("SellingPriceInClientCurrency"),
                };
        
        
                // Use `record.rowId` as the unique identifier
                const recordId = record.rowId || record.get("rowId");
                if (!recordId) {
                    return record; // Exit if rowId is missing
                }
        
                // Add the `recordId` to the purchase order line data
                purchaseOrderLineData.rowId = recordId;
        
                // Validate the foreign key (POR NUMBER) against `PuchaseOrders`
                const porNumber = purchaseOrderLineData.PORNumber;
                if (!purchaseOrderRecieved.some((purchaseOrder) => purchaseOrder.PORNumber.value === porNumber)) {
                    const errorMsg = `POR Number ${porNumber} does not exist in the Purchase Order table.`;
                    
                    record.addError("PORNumber", errorMsg);
                }
            
                // Validate the foreign key (ProductId) against `products`
                const productId = purchaseOrderLineData.ProductID;
                if (!products.some((product) => product.ID.value === productId)) {
                    const errorMsg = `Product ID ${productId} does not exist in the Products table.`;
                    
                    record.addError("ProductID", errorMsg);
                }

                // Validate Units field (should be a valid number: float or int)
                const units = parseFloat(purchaseOrderLineData.Units);
                if (isNaN(units) || !/^[-+]?\d+(\.\d+)?$/.test(purchaseOrderLineData.Units)) {
                    record.addError("Units", "Units must be a valid number.");
                }else if( units < 0 ){
                    record.addError("Units", "Units must be a non-negative number.");                    
                }

                
                // Validate RateToBaseCurrency field (should be a valid number: float or int)
                const sellingPriceInClientCurrency = parseFloat(purchaseOrderLineData.SellingPriceInClientCurrency);
                if (isNaN(sellingPriceInClientCurrency) || !/^[-+]?\d+(\.\d+)?$/.test(purchaseOrderLineData.SellingPriceInClientCurrency)) {
                    record.addError("SellingPriceInClientCurrency", "Selling Price In Client Currency must be a valid number.");
                }else if( sellingPriceInClientCurrency < 0 ){
                    record.addError("SellingPriceInClientCurrency", "Selling Price In Client Currency must be a non-negative number.");    
                }


                return record; 
            })
        );   

        return (
            <div className="content">
                    <Space config={{
                        name: `${companyData.id}_service`, 
                        metadata: {
                            sidebarConfig: { showSidebar: false },
                            theme: { root: { actionColor: "#1C83CB" } },
                        },
                    }}>
                        <div className="workbooks-layout">
                            <Workbook
                                config={serviceDataWorkbook}
                                onRecordHooks={[
                                    [(record) => record],
                                    [(record) => record],
                                ]}
                            />
                        </div>
                    </Space>       
            </div>
        );
    };

    return (

        <div className="workbooks-container">
            <div className="sidebar">
                <div className="icon-section">
                    <div 
                        className={`icon ${selectedSidebar === 'Reference data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Reference data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9C11.2044 9 10.4413 8.68393 9.87868 8.12132C9.31607 7.55871 9 6.79565 9 6C9 5.20435 9.31607 4.44129 9.87868 3.87868C10.4413 3.31607 11.2044 3 12 3C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6C15 6.79565 14.6839 7.55871 14.1213 8.12132C13.5587 8.68393 12.7956 9 12 9ZM5.5 21C4.70435 21 3.94129 20.6839 3.37868 20.1213C2.81607 19.5587 2.5 18.7956 2.5 18C2.5 17.2044 2.81607 16.4413 3.37868 15.8787C3.94129 15.3161 4.70435 15 5.5 15C6.29565 15 7.05871 15.3161 7.62132 15.8787C8.18393 16.4413 8.5 17.2044 8.5 18C8.5 18.7956 8.18393 19.5587 7.62132 20.1213C7.05871 20.6839 6.29565 21 5.5 21ZM18.5 21C17.7044 21 16.9413 20.6839 16.3787 20.1213C15.8161 19.5587 15.5 18.7956 15.5 18C15.5 17.2044 15.8161 16.4413 16.3787 15.8787C16.9413 15.3161 17.7044 15 18.5 15C19.2956 15 20.0587 15.3161 20.6213 15.8787C21.1839 16.4413 21.5 17.2044 21.5 18C21.5 18.7956 21.1839 19.5587 20.6213 20.1213C20.0587 20.6839 19.2956 21 18.5 21Z" stroke="#0077BE" strokeWidth="1.5"/>
                            <path opacity="0.5" d="M20 13C20.0014 11.8648 19.7605 10.7424 19.2935 9.70776C18.8265 8.67309 18.1442 7.74995 17.292 7M4.00001 13C3.99866 11.8648 4.23952 10.7424 4.7065 9.70776C5.17348 8.67309 5.85584 7.74995 6.70801 7M10 20.748C10.6534 20.916 11.3254 21.0007 12 21C12.6746 21.0007 13.3466 20.916 14 20.748" stroke="#0077BE" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>

                    </div>

                    <div 
                        className={`icon center ${selectedSidebar === 'Sales data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Sales data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0077BE" strokeWidth="1.5"/>
                            <path d="M12 17V18M12 17C13.657 17 15 15.88 15 14.5C15 13.12 13.657 12 12 12C10.343 12 9 10.88 9 9.5C9 8.12 10.343 7 12 7M12 17C10.343 17 9 15.88 9 14.5M12 6V7M12 7C13.657 7 15 8.12 15 9.5" stroke="#0077BE" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>

                    </div>

                    <div 
                        className={`icon invalid-count ${selectedSidebar === 'Service data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Service data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2702 16.265L20.9752 12.185C21.0166 11.9459 21.0052 11.7006 20.9419 11.4663C20.8786 11.232 20.7648 11.0144 20.6086 10.8287C20.4524 10.643 20.2575 10.4936 20.0376 10.391C19.8176 10.2885 19.5779 10.2352 19.3352 10.235H14.1532C14.0324 10.2349 13.913 10.2085 13.8034 10.1576C13.6938 10.1068 13.5966 10.0327 13.5185 9.94049C13.4404 9.8483 13.3833 9.74023 13.3511 9.62376C13.3189 9.50729 13.3125 9.38522 13.3322 9.26601L13.9952 5.22101C14.1023 4.56422 14.0717 3.89233 13.9052 3.24801C13.8333 2.9819 13.6954 2.73823 13.5044 2.5395C13.3133 2.34077 13.0753 2.1934 12.8122 2.11101L12.6672 2.06401C12.3396 1.95938 11.9845 1.9837 11.6742 2.13201C11.3342 2.29601 11.0862 2.59501 10.9942 2.95001L10.5182 4.78401C10.3669 5.36772 10.1467 5.93135 9.86223 6.46301C9.44623 7.24001 8.80423 7.86301 8.13723 8.43801L6.69723 9.67801C6.49759 9.85052 6.34168 10.0679 6.24225 10.3123C6.14283 10.5567 6.10272 10.8211 6.12523 11.084L6.93823 20.477C6.974 20.8922 7.16411 21.279 7.47105 21.5609C7.77798 21.8429 8.17945 21.9995 8.59623 22H13.2452C16.7262 22 19.6972 19.574 20.2692 16.265" fill="#0077BE"/>
                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M2.96808 9.485C3.16134 9.47655 3.35039 9.5431 3.49574 9.67075C3.64109 9.7984 3.7315 9.97727 3.74808 10.17L4.71808 21.406C4.73451 21.5733 4.71666 21.7422 4.66562 21.9024C4.61458 22.0626 4.53142 22.2107 4.42122 22.3377C4.31102 22.4647 4.17609 22.5678 4.02468 22.6409C3.87327 22.714 3.70855 22.7555 3.54058 22.7627C3.37261 22.77 3.20492 22.743 3.04775 22.6833C2.89058 22.6236 2.74723 22.5325 2.62646 22.4155C2.50568 22.2986 2.41002 22.1582 2.3453 22.003C2.28059 21.8479 2.24819 21.6811 2.25008 21.513V10.234C2.25016 10.0407 2.32488 9.85486 2.45866 9.71531C2.59244 9.57576 2.77494 9.49325 2.96808 9.485Z" fill="#0077BE"/>
                        </svg>

                    </div>
                </div>
            </div>

            <FlatfileProvider publishableKey={PUBLISHABLE_KEY}>
                <SpaceConfig />
            </FlatfileProvider>
        </div>
    );
};

export default WorkbooksPage_service;
